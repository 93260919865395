import React from 'react'
import { graphql } from 'gatsby'

import PodcastPage from '../components/podcastPage'
import { Podcast } from '../types'

type PodcastTemplateProps = {
  data: {
    Content: {
      getContents: {
        items: Podcast[]
      }
    }
  }
}

const PodcastTemplate: React.FC<PodcastTemplateProps> = props => {
  const podcast = props.data.Content.getContents.items[0];
  
  return <PodcastPage podcast={podcast} />
}

export const query = graphql`
  query($slug: String!) {
    Content {
      getContents(limit: 1, filter: {type: { eq: "podcast" }, slug: { eq: $slug } }) {
        items {
          id
          slug
          title
          type
          publishDate
          subtitle
          tags
          thumbnail
          attributes {
            name
            value
          }
          sections {
            bodies {
              data
              type
              attributes {
                name
                value
              }
            }
            type
            attributes {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export default PodcastTemplate
