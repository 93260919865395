import React from 'react'
import { Podcast } from '../types'
import { Layout } from './layout'
import FullPodcast from './fullPodcast'

type PodcastPageProps = {
  podcast: Podcast,
}

const PodcastPage: React.FC<PodcastPageProps> = (props) => {
  const { podcast } = props;
  return (
    <Layout>
      <FullPodcast podcast={podcast} />
    </Layout>
  )
}

export default PodcastPage
